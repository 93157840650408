import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MenuSubItem } from '../../../core/models/Menu';

type Props = {
    title: string;
    url: string;
    subItems?: MenuSubItem[];
    defaultOpened?: boolean;
    navigate?: boolean;
};

export const MenuItem = ({
    title,
    url,
    subItems = [],
    defaultOpened = false,
    navigate = true,
}: Props) => {
    const { type } = useParams();

    const [opened, setOpened] = useState(defaultOpened);
    const [mouseOver, setMouseOver] = useState(false);

    const onMouseOver = () => setMouseOver(true);
    const onMouseOut = () => setMouseOver(false);

    const filteredSubItems = subItems
        .filter((i) => i.visible)
        .sort((a, b) => a.position - b.position);

    useEffect(() => {
        if (!defaultOpened) {
            setOpened(mouseOver || '/' + type === url);
        }
        // eslint-disable-next-line
    }, [mouseOver, type]);

    return (
        <div onMouseOver={onMouseOver} onMouseOut={onMouseOut} className="mb-2">
            <a
                href={navigate ? url : '#'}
                className="list-group-item border-0 d-inline-block text-truncate ps-0 pb-1 bg-transparent"
            >
                <span>
                    <b>{title}</b>
                </span>
            </a>
            {opened && filteredSubItems.length > 0 && (
                <div
                    className="d-flex flex-column"
                    style={{ marginTop: '-8px' }}
                >
                    {filteredSubItems.map((item) => (
                        <a
                            key={item.url}
                            href={navigate ? item.url : '#'}
                            className="list-group-item border-0 d-inline-block text-truncate ps-0 py-1"
                        >
                            <span
                                style={{
                                    marginLeft: '8px',
                                    fontSize: '14px',
                                }}
                            >
                                {item.title}
                            </span>
                        </a>
                    ))}
                </div>
            )}
        </div>
    );
};
