import React, { useEffect } from 'react';
import { Menu, MenuResponse } from '../../../core/models/Menu';
import { MenuItem } from './MenuItem';
import { useGetAll } from '../../../core/hooks/firebase/useGetAll';
import { menuMapper } from '../../../core/mapper/menuMapper';
import { useTranslation } from 'react-i18next';
import { SortDirection } from '../../../core/models/shared/SortDirection';
import clsx from 'clsx';
import { LanguageSelector } from '../../shared/LanguageSelector';
import { RESOURCES } from '../../../core/constants/resources';
import { BACK_ROOT } from 'core/constants/routes';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../firebase';
import { Language } from 'core/models/Language';
import { setLanguage } from 'i18n';
import useBootstrapScreenSize from 'core/hooks/bootstrap/useBootstrapScreenSize';

type Props = {
    breakPoint: 'sm' | 'md' | 'lg' | 'xl';
    height?: string;
    className?: string;
    defaultOpened?: boolean;
    showBackendButton?: boolean;
    showLanguageSelector?: boolean;
    navigate?: boolean;
};

export const SideBarMenu = ({
    breakPoint,
    height,
    className,
    defaultOpened = false,
    showBackendButton = false,
    showLanguageSelector = true,
    navigate = true,
}: Props) => {
    const { i18n } = useTranslation();
    const [user] = useAuthState(auth);

    const { data: menus, mutation: menuMutation } = useGetAll<
        Menu,
        MenuResponse
    >({
        resource: RESOURCES.MENU,
        mapper: menuMapper(i18n.language),
        filters: { visible: true },
        sort: { key: 'position', direction: SortDirection.ASC },
    });

    const { data: languages } = useGetAll<Language, Language>({
        resource: RESOURCES.LANGUAGES,
        mapper: (obj) => obj,
        filters: { visible: true },
        sort: { key: 'position', direction: SortDirection.ASC },
    });

    const { level } = useBootstrapScreenSize();

    useEffect(() => {
        menuMutation();
    }, [i18n.language]);

    return (
        <div
            className={clsx('col-auto', 'px-0', className, {
                'bg-transparent': level > 3,
                'bg-white': level <= 3,
            })}
            style={{ marginTop: height }}
        >
            <div className="collapse collapse-horizontal d-flex flex-column">
                <div className="list-group border-0 rounded-0 mx-0">
                    {menus.map((element: Menu) => (
                        <MenuItem
                            key={element.url}
                            title={element.title}
                            url={element.url}
                            subItems={element.subItems}
                            defaultOpened={defaultOpened}
                            navigate={navigate}
                        />
                    ))}
                    {user && showBackendButton && (
                        <MenuItem
                            title="** back **"
                            url={BACK_ROOT}
                            defaultOpened={defaultOpened}
                            navigate={navigate}
                        />
                    )}
                </div>
                {showLanguageSelector && (
                    <div
                        className={clsx('d-block my-2', `d-${breakPoint}-none`)}
                    >
                        <LanguageSelector
                            languages={languages}
                            className="d-flex justify-content-center align-items-center"
                            onChange={(code: string) => setLanguage(code)}
                            optionStyles={{ fontSize: '11px' }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
